import React from 'react';
import Form from '../common/form';
import Joi from 'joi-browser'
import ideasService from '../../services/ideasService'
import { canSubmit } from '../../actions/can-submit'
import { connect } from 'react-redux'
import Completed from './completed';
import SubmitIdeaForm from './submit-idea-form';
import SubmitIdeaVideo from './submi-idea-video';
import userService from '../../services/userService'
import Loading from '../common/loading'


class Ideas extends Form {

    state = {
        data: {
            teamName: '',
            thema: '',
            thema_count: 0,
            description: '',
            description_count: 0,
            origin: '',
            origin_count:0,
            files: []
        },

        hasSubmited: false,
        hasSaved:false,
        notPartOfTeam:false,
        errors: {},
        imageError: false,
        showVideo: true,
        loading: false,
        loadingError: '',
        submissionEligibleTeams:[],
        showSubmission:false,
        fileDeleted:false,
        showExistingFile:false,
        existingFileName:'',
        screenLoading:true


    }

    componentDidMount() {
        ideasService.hasSubmited().then(({ data:response }) => {

            this.setState({
                hasSubmited:response.hasSubmit,
                hasSaved:response.hasSaved,
                notPartOfTeam:!response.team
            })
            this.getUser()
        })

        this.props.canSubmitIdea();
    }

    showImageError = show => {
        this.setState({
            imageError: show
        })
    }

    schema = {
        teamName: Joi.string().required(),
        thema: Joi.string().required(),
        thema_count: Joi.number().optional(),
        description: Joi.string().required(),
        description_count: Joi.number().optional(),
        origin: Joi.string().required(),
        origin_count: Joi.number().optional(),
        files: Joi.array()
    }

    submitForm = () => {
        const { canSubmitProp, canSubmitIdea } = this.props;
        canSubmitIdea();
        if (!canSubmitProp) return false;

        this.setState({
            loading: true,
            loadingError: ''
        })

        const { teamName, thema, description, origin, files } = this.state.data;
        let isupdate = this.state.hasSaved

        // The component contains code to handle multiple submit
        // for now, each each save is a submit, use this variable incase save/submit have to handled seperately

        let submitted =true;

        if (!isupdate) {
            ideasService.submitIdea(teamName, thema, description, origin, files ,submitted).then(({ data }) => {

                console.log(data)
                this.setState({
                    hasSubmited:true,
                    showCompleted: true,
                    loading: false,
                    loadingError: ''
                })
            }).catch(err => {
                this.setState({
                    loading: false,
                    loadingError: 'Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.'
                })
            })
        }
        else{
            ideasService.updateIdea(teamName, thema, description, origin, files,this.state.fileDeleted,submitted).then(({ data }) => {
                this.setState({
                    hasSubmited:true,
                    showCompleted: true,
                    loading: false,
                    loadingError: ''
                })
            }).catch(err => {
                this.setState({
                    loading: false,
                    loadingError: 'Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.'
                })
            })
        }

        this.setState({
                showCompleted: true,
                loading: false,
                loadingError: ''
            })
       
    }

    hideVideo = () => {
        this.setState({
            showVideo: false
        });
    }

    getUser = () => {
        if(this.props.user)
        {   
            userService.getUser(this.props.user.id).then((response) => {
                this.setState({
                    userData: response.data,
                })
                
                if(!response.data.team){
                    this.setState({
                        notPartOfTeam:true
                    })
                    return
                
                }

                let updatedData = {...this.state.data}
                updatedData.teamName = response.data.team.team_name;

                const {hasSaved, hasSubmited, notPartOfTeam} = this.state


                if(hasSubmited){
                    ideasService.getIdeaByTeam(response.data.team.team_name).then(response => {
                        let idea = response.data;
                        console.log(idea)
                        // updatedData.teamName = response.data.team.team_name;
                        updatedData.thema = idea.theme;
                        updatedData.thema_count = idea.theme ? idea.theme.length:0;
                        updatedData.description = idea.description;
                        updatedData.description_count = idea.description ? idea.description.length: 0;
                        updatedData.origin=idea.origin;
                        updatedData.origin_count = idea.origin ? idea.origin.length:0;
                        updatedData.files=[];
                        let filename = '';
                        if(idea.file){
                            filename= idea.file.split('\\').pop().split('/').pop()     
                        }
        
                        this.setState({
                            data:updatedData,
                            existingFileName:filename,
                            showExistingFile:filename && filename!==''?true:false,
                            fileDeleted:false
                        })
                        
                    }).catch(e=>{
                        this.setState({
                            data:updatedData,
                            existingFileName:'',
                            showExistingFile:false,
                            fileDeleted:false
                        });
                    }).then(()=>{
                        this.setState({showSubmission:true})     
                    })
                    
                }
                else{
                    this.setState({data:updatedData, 
                                    existingFileName:'',
                                    showExistingFile:false,
                                    fileDeleted:false,
                                    showSubmission:true
                                })
                }

            })
        }
    }

    handleExistingFileDelete = () => {
        this.setState({
            showExistingFile:false,
            existingFileName:'',
            fileDeleted:true
            
        })
    }

    //Perform char counts
    handleCharChange = (name, max_count) => (event) => {
        const { data } = this.state;
        let title = event.target.value;
        switch(name){
            case 'origin':
                if (title.length >= max_count){
                    data.origin = title.substring(0, max_count);
                    data.origin_count = data.origin.length;
                }
                else{data.origin = event.target.value;
                    data.origin_count = data.origin.length;}
                break;

            case 'description':
                if (title.length >= max_count){
                    data.description = title.substring(0, max_count);
                    data.description_count = data.description.length;
                }
                else{data.description = event.target.value;
                    data.description_count = data.description.length;}
                break;
            case 'thema':
                if (title.length >= max_count){
                    console.log('Theme length', title.length)
                    data.thema = title.substring(0, max_count);
                    data.thema_count = data.thema.length;
                }
                else{data.thema = event.target.value;
                    data.thema_count = data.thema.length;}
                break;
        }
        
        this.setState({
            data: data
        });
    
    };

    // Perform Word Count
    handleTextChange = (name, max_count) => (event) => {
        const { data } = this.state;
        let words = event.target.value.split(' ');
        if (words.length >= max_count){
            let vals = '';
            words.forEach(function (word, idx) {
                if (idx == max_count - 1){           
                        vals += word.trim();
                        
                }
                else if (idx < max_count){
                        vals += word + ' ';
                }
            });
            if (name ==='origin'){
                data.origin = vals;
                data.origin_count = max_count;
            }
            else if (name ==='description'){
                data.description = vals;
                data.description_count = max_count;
            }
            
        }
        else{

            if (name === 'origin'){
                data.origin = event.target.value;
                if (words.length > 0){
                    data.origin_count = words.length;
                }
            }
            else if (name === 'description'){
                data.description = event.target.value;
                if (words.length > 0){
                    data.description_count = words.length;
                }
            }        
           
        }

        this.setState({
            data: data
        });
    };


    render() {
        const { hasSubmited, 
                showCompleted, 
                showVideo, 
                data, 
                loading, 
                loadingError, 
                userData,
                notPartOfTeam,
                showSubmission,
                existingFileName,
                showExistingFile,
                word_count,
                imageError,
                screenLoading } = this.state;
        const { canSubmitProp } = this.props;

        return (
            <React.Fragment>

                {
                    // !showCompleted &&
                    <section className="content question">
                        <div className="container-fluid">
                            <div className="container-limit my-limit">
                                <React.Fragment>
                                    {
                                        !notPartOfTeam && showSubmission &&
                                        <SubmitIdeaForm canSubmitProp={canSubmitProp} 
                                                        loading={loading} 
                                                        loadingError={loadingError} 
                                                        data={data}
                                                        user={userData}
                                                        handleTextChange={this.handleTextChange}
                                                        handleCharChange={this.handleCharChange}
                                                        handleChange={this.handleChange} 
                                                        handleSubmit={this.handleSubmit} 
                                                        validate={this.validate} 
                                                        showImageError={this.showImageError}
                                                        existingFileName={existingFileName}
                                                        word_count={word_count}
                                                        showExistingFile={showExistingFile}
                                                        handleExistingFileDelete={this.handleExistingFileDelete}
                                                        hasSubmit={hasSubmited}
                                                        showCompleted={showCompleted} 
                                                        imageError={imageError}/>
                                    }
                                    {
                                        
                                        notPartOfTeam  &&
                                        <div className="submitedIdea">
                                            <div className="submited-wrapper">
                                                <h1>Um ein Projekt zu registrieren und eine Idee einzureichen, musst du als Mitglied eines Teams registriert sein.</h1>
                                            </div>
                                        </div>
                                        
                                    }
                                    {
                                        
                                        canSubmitProp && !notPartOfTeam && !showSubmission && <Loading /> 
                                    }
                                </React.Fragment>
                            </div>
                        </div>
                    </section>
                }
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        canSubmitProp: state.canSubmit
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        canSubmitIdea: () => {
            dispatch(canSubmit())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ideas);
