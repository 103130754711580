import React from 'react';
import { FilePond, File } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

const SubmitPostcardForm = ({ user,
    data,
    loading,
    showImageError,
    imageError,
    handleChange,
    validate,
    handleCharChange,
    handleSubmit,
    loadingError,
    showExistingFile,
    showConfirmationPopup,
    existingFileName,
    handleExistingFileDelete,
    showCompleted,
    hasSubmited }) => {
    const { teamName, teamMembers, title, title_count, description, file } = data;


    return (
        <div>
            <div className='row'>
                <div className='col-md-12'>
                    <h2 className="text-center primary-color-lightgreen-foreground">Postkartenwettbewerb</h2>
                    <div className='header2 font-sub-header text-justify'>
                            <span>Möchtest du dein Projekt auf einer Postkarte grafisch darstellen? Hier kannst du deinen Entwurf hochladen. Die Gewinner erhalten Preise und der Entwurf wird im kommenden Jahr als BWKI-Postkarte verfügbar sein.</span>
                    </div>
                </div>
            </div>
            <form onSubmit={showConfirmationPopup}>
                <div className='row mt-5'>
                    <div className="form-group col-md-12">
                        <label for='labelDesc'>Teamname</label>
                        <input className='form-control' name='teamName' id='teamName' value={teamName} onChange={handleChange} readOnly type="text" />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label for='labelDesc'>Titel des Projekts (max. 150 Zeichen inkl. Leerzeichen)</label>
                        <input className='form-control' name='title' id='title' readOnly={hasSubmited} value={title} onChange={handleCharChange('title', 150)} type="text" />
                        <span className='float-right'>Zeichen: {title_count}/150</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label for='labelDesc'>Alle Mitglieder des Teams mit ihrem vollen Namen und Benutzernamen</label>
                        <input className='form-control' name='teamMembers' id='teamMembers' value={teamMembers} onChange={handleChange} readOnly type="text" />
                    </div>
                </div>

                {/* <div className='row'>
                    <div className="form-group beschreibung col-md-12">
                        <label for='labelDesc'>Was ist auf eurer Postkarte zu sehen? Wie hängt sie mit eurem Projekt zusammen?</label>
                        <textarea className='form-control' name='description' id='description' readOnly={hasSubmited} value={description} onChange={handleChange}></textarea>
                    </div>
                </div> */}
                <div className='row mt-3'>
                    <div className="col-md-12">
                        <h2 className="text-center primary-color-lightgreen-foreground">Hochgeladene Dateien (max 10 MB)</h2>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="form-group pdf col-md-12 mb-0">
                        {/* <label for='labelDesc'>Hier könnt ihr eure Postkarte als PDF hochladen.(max. 10MB)</label> */}
                        {!showExistingFile && !hasSubmited && <FilePond
                            allowMultiple={true}
                            maxFiles={5}
                            beforeAddFile={(file) => { if ((file.fileSize / 1000000) > 10) { showImageError(true); return false; } else showImageError(false) }}
                            onupdatefiles={(fileItems) => {
                                handleChange({ currentTarget: { type: 'file', name: 'files', value: fileItems.map(fileItem => fileItem.file) } })
                            }
                            } />
                        }
                      
                        {showExistingFile &&
                            <div className="pdf existing-files">
                                <div className="file-info">
                                    {!hasSubmited && <div className="clear-button" onClick={handleExistingFileDelete}>
                                        <div className="clear-icon">x</div>
                                    </div>}
                                    <span>{existingFileName}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {imageError && <div className='alert alert-danger'>Ihr könnt Dateien bis zu maximal 10MB hochladen.</div>}
                {loadingError && <div className='alert alert-danger'>{loadingError}</div>}

                {!hasSubmited && !showCompleted && <button disabled={loading ? loading : validate()} className="btn btn-primary">{loading ? "Loading..." : "Absenden"}</button>}
                {showCompleted && <div className="alert alert-success">
                    Du hast deinen Entwurf für den Postkartenwettbewerb erfolgreich eingereicht.
                </div>}

                <div className="row mt-5">
                    <div className="col-md-12">
                    <p className='text-center'>Probleme? Dann schreibt uns: <a href='mailto:bwki@asai.ac.at'><b>bwki@asai.ac.at</b></a></p>
                    </div>
                </div>
            </form>

        </div>
    );
}

export default SubmitPostcardForm;