import http from './httpService';

const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;


export async function leaveTeam(teamName) {
    return await http.post(`${web_api_url}/team-leave/${teamName}`)
}

export async function addMemberToTeam(teamMember,teamName) {
    return await http.post(`${web_api_url}/team_member`,{teamName,teamMember} )
}

export async function createTeam(teamName, username, username1, username2, username3, username4) {

    let teamMembers = [username1, username2, username3, username4];
    teamMembers=teamMembers.filter(item => item.length > 0)
    return await http.post(`${web_api_url}/team`, { teamName, teamMembers})
}

export async function updateTeam(teamName, username, username1, username2, username3, username4) {

    let teamMembers = [username1, username2, username3, username4];
    teamMembers=teamMembers.filter(item => item.length > 0)
    return await http.put(`${web_api_url}/team/${teamName}`, {teamMembers} )
}

export default {
    createTeam,
    leaveTeam,
    addMemberToTeam,
    updateTeam
}
