import React, { Component } from 'react';
import auth from '../../services/authService';
import { Redirect } from 'react-router-dom';
import Login from './login';
import Register from './register';
import ForgotPassword from './forgot-password';
import * as alert from '../../alert.json';
import { all_messages } from '../../jsons/all_messages_dialog.json'

class Auth extends Component {
    state = {
        activeTab: 'login',
        registerTeam: false,
        olderWarning: false,
        youngerWarning: false,
        showAlert: true
    }

    componentDidMount() {
        if (alert.auth.enabled) {
            this.setState({
                showAlert: true
            })
        }
    }

    changeTabs = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    changeIsTeam = value => {
        this.setState({
            registerTeam: value
        })
    }

    onShowOlderWarning = show => {
        this.setState({
            olderWarning: show
        })
    }

    onShowYoungerWarning = show => {
        this.setState({
            youngerWarning: show
        })
    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }

    render() {
        if (auth.getCurrentUser()) return <Redirect to='/' />

        const { activeTab, registerTeam, olderWarning, youngerWarning, showAlert, showPanel, modal } = this.state;
        return (
            <div className="page">
                {
                    alert.auth.enabled &&
                    <div className={`myAlert auth ${showAlert ? 'show' : ''}`}>
                        <small dangerouslySetInnerHTML={{ __html: alert.auth.message }} />
                        <i onClick={this.removeAlert} className="fa fa-times"></i>
                    </div>
                }
                <div className="auth-wrapper">
                    <div className={`login ${activeTab === 'register' ? (registerTeam ? 'activeTeam' : 'active') : ''} ${activeTab === 'register' && olderWarning ? 'warning-active' : '' && youngerWarning ? 'warning-active' : ''}`}>
                        <div className="nav-login">
                            <a onClick={() => this.changeTabs('login')} className={`${activeTab === 'login' ? 'active' : ''} user-login`}>
                                <span>Anmelden</span>
                            </a>
                            <a onClick={() => this.changeTabs('register')} className={`${activeTab === 'register' ? 'active' : ''} team-login`}>
                                <span>Registrieren</span>
                            </a>
                        </div>
                        <div className="content-login">
                            <Login changeTabs={this.changeTabs} activeTab={activeTab} />
                            <Register changeIsTeam={this.changeIsTeam} activeTab={activeTab} olderWarning={olderWarning} onShowOlderWarning={this.onShowOlderWarning} youngerWarning={youngerWarning} onShowYoungerWarning={this.onShowYoungerWarning} />
                            <ForgotPassword activeTab={activeTab} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Auth;
