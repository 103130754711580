import http from './httpService';


const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;

const multipartHeader = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}

export async function submitPostcard(teamName, teamMembers, title, file,submitted) {
    let data = new FormData();
    data.append('teamName', teamName);
    data.append('teamMembers', teamMembers);
    data.append('title', title);
    data.append('submitted', submitted)

    if (file) {
        for (let i = 0; i < file.length; i++) {
            data.append('files', file[i], file[i].name)
        }
    }

    return await http.post(`${web_api_url}/postcard`, data, multipartHeader)
}

export async function hasSubmited() {
    return await http.get(`${web_api_url}/has-submit-postcard`);
}

export async function getPostcardByTeam(teamName) {
    return await http.get(`${web_api_url}/postcard/${teamName}`);
}


export default {
    submitPostcard,
    hasSubmited,
    getPostcardByTeam
}
