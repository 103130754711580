import React from 'react';
import teamFindungService from '../../services/teamFindungService';
import moment from 'moment';
import Form from '../common/form';
import Joi from 'joi-browser';
import { Link } from 'react-router-dom';
import { getUserIdColor } from '../../services/authService';
import Identicon from 'react-identicons';

class ViewTeamFindung extends Form {
    _isMounted = false;
    constructor(props) {
        super(props);

        const { params } = props.match;
        if (!params.id) return props.history.push('/team-findung');

        this.state = {
            data: {
                comment: ''
            },
            errors: {},
            id: params.id,
            title: '',
            description: '',
            postedOn: '',
            username: '',
            userId: '',
            loading: false,
            errorLoading: false,
            sent: false
        }
    }

    schema = {
        comment: Joi.string().required()
    }

    componentDidMount() {
        this._isMounted = true;
        const { id } = this.state;
        teamFindungService.getTeam(id).then(({ data }) => {
            const teamObj = JSON.parse(data);

            if(this._isMounted)
            {
                this.setState({
                    title: teamObj[0].title,
                    description: teamObj[0].description,
                    postedOn: teamObj[0].postedOnDate['$date'],
                    username: teamObj[0].username,
                    userId: teamObj[0].userId
                })
            }
        })
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    submitForm = () => {
        this.setState({
            loading: true,
            errorLoading: false,
            sent: false
        });

        const { id, data } = this.state;
        const { comment } = data;

        teamFindungService.addComment(id, comment).then(({ data }) => {
            data.comment = '';
            if(this._isMounted)
            {
                this.setState({
                    loading: false,
                    errorLoading: false,
                    sent: true,
                    data
                });
            }
        }).catch(err => {
            if(this._isMounted)
            {
                this.setState({
                    loading: false,
                    errorLoading: true,
                    sent: false
                });
            }
        });
    }

    render() {
        const { title, description, postedOn, username, userId, data, errors, loading, errorLoading, sent } = this.state;
        const { comment } = data;

        return (
            <section className="content question">
                <div className="container-fluid">
                    <div className="container-limit my-limit">
                        
                        <div className="col-md-12">
                            <div className="row">
                                <div className="view-team-finding-header"><strong>{`${title} von ${username}`}</strong></div>
                                
                            </div>
                            <div className="row">
                                <div className="col-md-12 view-team-finding-description">{description}</div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="view-team-finding-header">{`Schreibe eine Nachricht an `} <strong>{username}</strong> </div>
                                
                            </div>

                            <div className="row">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label>Deine Nachricht: </label>
                                        <textarea className="form-control view-team-finding-comment" name='comment' id='comment' value={comment} onChange={this.handleChange}></textarea>
                                        <label className="mt-1">{`Deine Nachricht wird an `}<strong>{username}</strong> {` per email gesendet. Er/Sie erhält deine Email Adresse und kann dir dann antworten.`}</label>
                                    </div>
                                    
                                    {errorLoading && <p className='text-danger'>Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.</p>}
                                    {sent && <p className='text-success'>Email erfolgreich versandt!</p>}
                                    {this.renderSubmitButton('Absenden', loading, 'btn btn-primary comment-button no-margin-top')}
                                </form>
                                                 
                            </div>
                            <div className="row">
                                <div className='col-md-12 mt-5 text-center'>
                                    <p>Probleme? Dann schreibt uns: <b>bwki@asai.ac.at</b></p>
                                </div>
                            </div>
                            

                        </div>



                        {/* <div style={{ paddingTop: 100 }}>
                            <h4>{title}</h4>
                            <div className="viewTeam">
                                <p>{description}</p>
                                <div className="viewTeam-user">
                                    <span>by: </span>
                                    <div className="viewTeam-userImg">
                                        <Identicon palette={[getUserIdColor(userId)]} string={username} />
                                    </div>
                                    <div className="user-date">
                                        <span>{username}</span>
                                        <span>{moment(postedOn).format('DD/MM/YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="textAreaTeam">
                                    <textarea name='comment' id='comment' value={comment} onChange={this.handleChange}></textarea>
                                </div>
                                <p className="blueColor" >Dein Kommenter wird per Email an <strong>{username}</strong> gesendet. Er oder sie hat Einblick auf deine Email-Adresse und kann dir per Email antworten.</p>
                                {errorLoading && <p className='text-danger'>Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.</p>}
                                {sent && <p className='text-success'>Email erfolgreich versandt!</p>}
                                {this.renderSubmitButton('Absenden', loading, 'btn btn-primary comment-button')}
                            </form>
                            <div className="button-zuruck">
                                <Link to='/team-findung' className='btn btn-primary'>Zuruck</Link>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}

export default ViewTeamFindung;
