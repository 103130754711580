import React from 'react';
import Form from '../common/form';
import authService from '../../services/authService';
import '../../datepicker.css';
import EditUser from './components/edit_user';
import Team from './components/team';
import Einladung from './components/einladung';
import userService from '../../services/userService';
import Joi from 'joi-browser';

class UserProfile extends Form {
    constructor(props)
    {
        super(props);
        this.state = {
            data: {},
            errors: []
        }
    }

    componentDidMount(){
        this.getUser()
    }

    getUser = () => {
        if(this.props.user)
        {
            userService.getUser(this.props.user.id).then(({data:response}) => {
                this.setState({
                    data: response                
                })           

            })
        }
    }
    
    render() {
        const { data} = this.state;

        return(
            <React.Fragment>
                <section className="content question">
                    <div className="container-fluid">
                        <div className="container-limit">
                            <div className={`user-profile`}>
                                <EditUser response={data} getUser={this.getUser} />                               
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default UserProfile;