import React from 'react';
import Form from '../common/form';
import Joi from 'joi-browser'
import postcardsService from '../../services/postcardsService'
import { canSubmit } from '../../actions/can-submit'
import { connect } from 'react-redux'
import Completed from './completed';
import SubmitPostcardsForm from './submit-postcards-form';
import userService from '../../services/userService';
import Loading from '../common/loading'


class Postcards extends Form {

    state = {
        data: {
            teamName: '',
            teamMembers: '',
            title: '',
            title_count: 0,
            files: []
        },
        hasSubmited: false,
        hasSaved:false,
        notPartOfTeam:false,
        errors: {},
        imageError: false,
        showVideo: false,
        loading: false,
        loadingError: '',
        userData:{},
        showSubmission:false,
        togglePopup:false,
        existingFileName:'',
        showExistingFile:false

        
    }

    //Perform char counts
    handleCharChange = (name, max_count) => (event) => {
        const { data } = this.state;
        let title = event.target.value;
        switch(name){
            case 'title':
                if (title.length >= max_count){
                    data.title = title.substring(0, max_count);
                    data.title_count = data.title.length;
                }
                else{data.title = event.target.value;
                    data.title_count = data.title.length;}
                break;
        }
        
        this.setState({
            data: data
        });
    
    };

    handleExistingFileDelete = () => {
        this.setState({
            showExistingFile:false,
            existingFileName:'',
            fileDeleted:true
            
        })
    }

    getUser = () => {
        if(this.props.user)
        {   
            userService.getUser(this.props.user.id).then((response) => {
                this.setState({
                    userData: response.data,
                })


                if(!response.data.team){
                    this.setState({
                        allowSubmission:false,
                        notPartOfTeam:true
                    })
                    return
                }

                let updatedData = {...this.state.data}
                updatedData.teamName = response.data.team.team_name;

                let defaultMembers = response.data.team.team_members? response.data.team.team_members:[]
                let membersString = '';
                defaultMembers.filter(member => member.accepted).map((member) =>   {
                    membersString += member.username + ','
                })
                membersString.substr(0,membersString.length)
                updatedData.teamMembers = membersString;

                const {hasSaved, hasSubmited, notPartOfTeam} = this.state

                if(hasSaved){
                    postcardsService.getPostcardByTeam(response.data.team.team_name).then(response => {
                        let postcard = response.data;
                        console.log(postcard)     

                        this.setState({showSubmission:true})
                        updatedData.title = postcard.title;
                        updatedData.title_count = postcard.title ? postcard.title.length: 0;
                        updatedData.files=[];
                        let filename = '';
                        if(postcard.file){
                            filename= postcard.file.split('\\').pop().split('/').pop()     
                        }
        
                        this.setState({
                            data:updatedData,
                            existingFileName:filename,
                            showExistingFile:filename && filename!==''?true:false,
                            fileDeleted:false
                        })
                        
                    }).catch(e=>{
                        this.setState({
                            data:updatedData,
                            existingFileName:'',
                            allowSubmission:true,
                            showExistingFile:false,
                            fileDeleted:false
                        });
                    }).then(()=>{
                        this.setState({showSubmission:true})     
                    })
                    
                }
                else{
                    this.setState({data:updatedData, 
                                    existingFileName:'',
                                    showExistingFile:false,
                                    fileDeleted:false,
                                    allowSubmission:true,
                                    showSubmission:true})
                }

            })
        }
    }

    componentDidMount() {
        postcardsService.hasSubmited().then(({ data:response }) => {

            console.log(response)
            this.setState({
                hasSubmited: response.hasSubmit,
                hasSaved:response.hasSaved,
                notPartOfTeam:!response.team
            })
            this.getUser()
        })
        this.props.canSubmitPostcard();
    }

    showImageError = show => {
        this.setState({
            imageError: show
        })
    }

    schema = {
        teamName: Joi.string().required(),
        teamMembers: Joi.string().required(),
        title: Joi.string().required(),
        title_count: Joi.number().optional(),
        files: Joi.array()
    }

    submitForm = () => {
        const { canSubmitProp, canSubmitPostcard } = this.props;
        canSubmitPostcard();
        if (!canSubmitProp) return false;

        this.setState({
            loading: true,
            loadingError: ''
        })

        // send it as submit for all submissions, reset this to allow save
        let submitted =true;
        const { teamName, teamMembers, title, files } = this.state.data;
        postcardsService.submitPostcard(teamName, teamMembers, title, files, submitted).then(({ data:response }) => {


            let existingFileName = ''
            let showExistingFile = false;
            
            if(files && files.length > 0){
                existingFileName = files[0].name 
                showExistingFile=true
            }

            this.setState({
                hasSubmited:true,
                showCompleted: true,
                showExistingFile,
                existingFileName,
                loading: false,
                loadingError: '',
                togglePopup:false
            })
        }).catch(err => {
            this.setState({
                loading: false,
                loadingError: 'Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.',
                togglePopup:false
            })
        })
    }

    hideVideo = () => {
        this.setState({
            showVideo: false
        });
    }

    showConfirmationPopup = (event) => {
        event.preventDefault();
        this.setState({
            togglePopup:true
        });
    }

    onClosePupup = () => {
        this.setState({
            togglePopup:false
        });
    }

    onSubmitConfirmed = (event) => {
        this.handleSubmit(event);
    }

    onValidate = () => {

        let errors = this.validate()

        if (this.state.data.files.length === 0) {
            
            errors = errors?errors:{}
            errors['files'] = 'Postcarddatei darf nicht leer sein!'
        }
        return errors
    }

    render() {
        const { hasSubmited, 
                showCompleted, 
                showVideo, 
                data, 
                loading, 
                loadingError, 
                userData, 
                showExistingFile,
                existingFileName,
                togglePopup,
                showSubmission,
                notPartOfTeam,imageError} = this.state;
        const { canSubmitProp } = this.props;

        return (
            <React.Fragment>
                <div className={`user-popup ${togglePopup ? 'active' : ''}`}>
                    <div className={`popup-box`}> 
                        <span className={`text`} >Sobald der Postkartenentwurf eingereicht ist, können keine Veränderungen mehr vorgenommen werden.</span>
                        <div className={`popup-buttons`}>
                            <span disabled={this.state.loading} onClick={this.onSubmitConfirmed}>Bestätigen</span>
                            <span disabled={this.state.loading} onClick={this.onClosePupup}>Ablehnen</span>
                        </div>
                        { this.state.loading && <div className="in-progress">bitte warten.....</div>}
                    </div>  
                </div>

                {
                    // !showCompleted &&
                    <section className="content question">
                        <div className="container-fluid">
                            <div className="container-limit my-limit">
                                <React.Fragment>
                                    {
                                        // !hasSubmited && !showVideo && 
                                        this.props.canSubmitProp && showSubmission &&
                                        <SubmitPostcardsForm 
                                                            canSubmitProp={canSubmitProp} 
                                                            loading={loading} 
                                                            loadingError={loadingError} 
                                                            data={data} 
                                                            user={userData}
                                                            handleCharChange={this.handleCharChange}
                                                            handleChange={this.handleChange} 
                                                            handleSubmit={this.handleSubmit} 
                                                            validate={this.onValidate} 
                                                            showImageError={this.showImageError}
                                                            imageError={imageError}
                                                            showConfirmationPopup={this.showConfirmationPopup}
                                                            showCompleted={showCompleted}
                                                            hasSubmited={hasSubmited}
                                                            showExistingFile={showExistingFile}
                                                            existingFileName={existingFileName} />
                                    }
                                    {
                                        notPartOfTeam  &&
                                        <div className="submitedIdea">
                                            <div className="submited-wrapper">
                                                <h1>Um ein Postkarte einzureichen, musst Du als Mitglied eines Teams registriert sein.</h1>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !this.props.canSubmitProp && <div className="submitedIdea">
                                        <div className="submited-wrapper">
                                            <h1>Die Frist für die Einreichung von Postkarte ist abgelaufen. Sie können jetzt kein Postkarte einreichen.</h1>
                                        </div>
                                    </div>
                                    }
                                    {
                                        this.props.canSubmitProp && !notPartOfTeam && !showSubmission && <Loading />
                                    }
                                </React.Fragment>
                            </div>
                        </div>
                    </section>
                }

            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        canSubmitProp: state.canSubmit
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        canSubmitPostcard: () => {
            dispatch(canSubmit())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Postcards);
