import React from 'react';
import Form from '../common/form';
import Joi from 'joi-browser'
import projectService from '../../services/projectService'
import { canSubmit } from '../../actions/can-submit'
import { connect } from 'react-redux'
import Completed from './completed';
import SubmitProjectForm from './submit-project-form';
import SubmitProjectVideo from './submit-project-video';
import userService from '../../services/userService'
import Loading from '../common/loading'


class Projects extends Form {

    state = {
        data: {
            teamname: '',
            mitglieder: '',
            titel: '',
            titel_count: 0,
            ziel: '',
            ziel_count: 0,
            motivation: '',
            motivation_count: 0,
            anwendung: '',
            anwendung_count: 0,
            datensatz: '',
            datensatz_count: 0,
            vorbereitung: '',
            vorbereitung_count: 0,
            dnn: '',
            dnn_count: 0,
            evaluation: '',
            evaluation_count: 0,
            produkt: '',
            produkt_count: 0,
            komponenten: '',
            komponenten_count: 0,
            anleitung: '',
            anleitung_count: 0,
            probleme: '',
            probleme_count: 0,
            ethnic: '',
            ethnic_count: 0,
            potential: '',
            potential_count: 0,
            schwachstelle: '',
            schwachstelle_count: 0,
            zukunft: '',
            zukunft_count: 0,
            video: [],
            code: [],
            files: [],
            finaleAbgabe: false

        },
        hasSubmitted: false,
        hasSaved: false,

        errors: {},
        imageError: false,
        showVideo: true,
        loading: false,
        loadingError: '',
        userData: {},
        showSubmission: false,
        notPartOfTeam: false,
        projectSubmitted: false,
        existingFileNames: [],
        togglePopup: false,
        showSaved: false
    }


    getUser = () => {
        if (this.props.user) {
            userService.getUser(this.props.user.id).then(({ data: response }) => {
                this.setState({
                    userData: response.data,
                })

                if (!response.team) {
                    this.setState({
                        showSubmission: false,
                        notPartOfTeam: true
                    })
                    return
                }

                let updatedData = { ...this.state.data }
                updatedData.teamname = response.team.team_name;

                let defaultMembers = response.team.team_members ? response.team.team_members : []
                let membersString = '';
                defaultMembers.filter(member => member.accepted).map((member) => {
                    membersString += member.username + ','
                })
                membersString.substr(0, membersString.length)
                updatedData.mitglieder = membersString;

                console.log(response)

                const { hasSaved, hasSubmitted, notPartOfTeam } = this.state

                if (hasSaved) {
                    console.log("there exists a team and saved")


                    projectService.getProjectByTeam(response.team.team_name).then(({ data: response }) => {

                        console.log("there exists a team - get project")

                        const project = response
                        // TODO: update project fields from project to updatedData

                        console.log(project)

                        updatedData.titel = project.titel ? project.titel : "";
                        updatedData.titel_count = project.titel ? project.titel.length : 0;
                        updatedData.ziel = project.ziel ? project.ziel : "";
                        updatedData.ziel_count = project.ziel ? project.ziel.length : 0;
                        updatedData.motivation = project.motivation ? project.motivation : "";
                        updatedData.motivation_count = project.motivation ? project.motivation.length : 0;
                        updatedData.anwendung = project.anwendung ? project.anwendung : "";
                        updatedData.anwendung_count = project.anwendung ? project.anwendung.length : "";
                        updatedData.datensatz = project.datensatz ? project.datensatz : "";
                        updatedData.datensatz_count = project.datensatz ? project.datensatz.length : 0;
                        updatedData.vorbereitung = project.vorbereitung ? project.vorbereitung : "";
                        updatedData.vorbereitung_count = project.vorbereitung ? project.vorbereitung.length : 0;
                        updatedData.dnn = project.dnn ? project.dnn : "";
                        updatedData.dnn_count = project.dnn ? project.dnn.length : 0;
                        updatedData.evaluation = project.eval ? project.eval : "";
                        updatedData.evaluation_count = project.eval ? project.eval.length : 0;
                        updatedData.produkt = project.produkt ? project.produkt : "";
                        updatedData.produkt_count = project.produkt ? project.produkt.length : 0;
                        updatedData.komponenten = project.komponenten ? project.komponenten : "";
                        updatedData.komponenten_count = project.komponenten ? project.komponenten.length : 0;
                        updatedData.anleitung = project.anleitung ? project.anleitung : "";
                        updatedData.anleitung_count = project.anleitung ? project.anleitung.length : 0;
                        updatedData.probleme = project.probleme ? project.probleme : "";
                        updatedData.probleme_count = project.probleme ? project.probleme.length : 0;
                        updatedData.ethnic = project.ethnic ? project.ethnic : "";
                        updatedData.ethnic_count = project.ethnic ? project.ethnic.length : 0;
                        updatedData.potential = project.potential ? project.potential : "";
                        updatedData.potential_count = project.potential ? project.potential.length : 0;
                        updatedData.schwachstelle = project.schwachstelle ? project.schwachstelle: "";
                        updatedData.schwachstelle_count = project.schwachstelle ? project.schwachstelle.length : 0;
                        updatedData.zukunft = project.zukunft ? project.zukunft : "";
                        updatedData.zukunft_count = project.zukunft ? project.zukunft.length : 0;
                        updatedData.finaleAbgabe = project.submitted ? project.submitted : false;

                        let files = project.file && project.file.length > 0 ? project.file.map(file => { return file.split('\\').pop().split('/').pop() }) : [];

                        this.setState({
                            data: updatedData,
                            existingFileNames: files
                        })

                    }).catch(error => {
                        console.log(error)
                        // let updatedData = {...this.state.userData}
                        this.initDefaultStates(updatedData);
                        // this.setState({showSubmission:true})
                    }).then(() => {
                        console.log("in then")
                        this.setState({ showSubmission: true })
                    });
                }
                else {
                    // no project saved 
                    this.initDefaultStates(updatedData);
                    this.setState({ showSubmission: true })
                }


            })
        }
    }

    initDefaultStates(updatedData) {
        this.setState({
            data: updatedData,
            projectSubmitted: false,
            existingFileNames: []
        });
    }

    componentDidMount() {
        this.props.canSubmitProject();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.canSubmitProp) {
            console.log("can submit - passed")
            console.log(nextProps.canSubmitProp)
            projectService.hasSubmited().then(({ data }) => {

                console.log("on has submitted")
                this.setState({
                    hasSubmitted: data.hasSubmit,
                    notPartOfTeam: !data.team,
                    hasSaved: data.hasSaved
                })
                console.log("get user call")
                this.getUser()
            })
        }
    }

    showImageError = show => {
        this.setState({
            imageError: show
        })
    }


    // Perform Word Count
    handleTextChange = (name, max_count) => (event) => {
        const { data } = this.state;
        let words = event.target.value.split(' ');
        if (words.length >= max_count){
            let vals = '';
            words.forEach(function (word, idx) {
                if (idx == max_count - 1){           
                        vals += word.trim();
                        
                }
                else if (idx < max_count){
                        vals += word + ' ';
                }
            });
            if (name ==='evaluation'){
                data.evaluation = vals;
                data.evaluation_count = max_count;
            }
            
        }
        else{

            if (name === 'evaluation'){
                data.evaluation = event.target.value;
                if (words.length > 0){
                    data.evaluation_count = words.length;
                }
            }
        }

        this.setState({
            data: data
        });
    };

    //Perform char counts
    handleCharChange = (name, max_count) => (event) => {
        const { data } = this.state;
        let title = event.target.value;
        switch (name) {
            case 'titel':
                if (title.length >= max_count) {
                    data.titel = title.substring(0, max_count);
                    data.titel_count = data.titel.length;
                }
                else {
                    data.titel = event.target.value;
                    data.titel_count = data.titel.length;
                }
                break;
            case 'ziel':
                if (title.length >= max_count) {
                    data.ziel = title.substring(0, max_count);
                    data.ziel_count = data.ziel.length;
                }
                else {
                    data.ziel = event.target.value;
                    data.ziel_count = data.ziel.length;
                }
                break;
            case 'motivation':
                if (title.length >= max_count) {
                    data.motivation = title.substring(0, max_count);
                    data.motivation_count = data.motivation.length;
                }
                else {
                    data.motivation = event.target.value;
                    data.motivation_count = data.motivation.length;
                }
                break;
            case 'anwendung':
                if (title.length >= max_count) {
                    data.anwendung = title.substring(0, max_count);
                    data.anwendung_count = data.anwendung.length;
                }
                else {
                    data.anwendung = event.target.value;
                    data.anwendung_count = data.anwendung.length;
                }
                break;
            case 'datensatz':
                if (title.length >= max_count) {
                    data.datensatz = title.substring(0, max_count);
                    data.datensatz_count = data.datensatz.length;
                }
                else {
                    data.datensatz = event.target.value;
                    data.datensatz_count = data.datensatz.length;
                }
                break;
            case 'vorbereitung':
                if (title.length >= max_count) {
                    data.vorbereitung = title.substring(0, max_count);
                    data.vorbereitung_count = data.vorbereitung.length;
                }
                else {
                    data.vorbereitung = event.target.value;
                    data.vorbereitung_count = data.vorbereitung.length;
                }
                break;
            case 'dnn':
                if (title.length >= max_count) {
                    data.dnn = title.substring(0, max_count);
                    data.dnn_count = data.dnn.length;
                }
                else {
                    data.dnn = event.target.value;
                    data.dnn_count = data.dnn.length;
                }
                break;
            case 'evaluation':
                if (title.length >= max_count) {
                    data.evaluation = title.substring(0, max_count);
                    data.evaluation_count = data.evaluation.length;
                }
                else {
                    data.evaluation = event.target.value;
                    data.evaluation_count = data.evaluation.length;
                }
                break;
            case 'produkt':
                if (title.length >= max_count) {
                    data.produkt = title.substring(0, max_count);
                    data.produkt_count = data.produkt.length;
                }
                else {
                    data.produkt = event.target.value;
                    data.produkt_count = data.produkt.length;
                }
                break;
            case 'komponenten':
                if (title.length >= max_count) {
                    data.komponenten = title.substring(0, max_count);
                    data.komponenten_count = data.komponenten.length;
                }
                else {
                    data.komponenten = event.target.value;
                    data.komponenten_count = data.komponenten.length;
                }
                break;
            case 'anleitung':
                if (title.length >= max_count) {
                    data.anleitung = title.substring(0, max_count);
                    data.anleitung_count = data.anleitung.length;
                }
                else {
                    data.anleitung = event.target.value;
                    data.anleitung_count = data.anleitung.length;
                }
                break;
            case 'probleme':
                if (title.length >= max_count) {
                    data.probleme = title.substring(0, max_count);
                    data.probleme_count = data.probleme.length;
                }
                else {
                    data.probleme = event.target.value;
                    data.probleme_count = data.probleme.length;
                }
                break;
            case 'ethnic':
                if (title.length >= max_count) {
                    data.ethnic = title.substring(0, max_count);
                    data.ethnic_count = data.motivation.length;
                }
                else {
                    data.ethnic = event.target.value;
                    data.ethnic_count = data.motivation.length;
                }
                break;
            case 'potential':
                if (title.length >= max_count) {
                    data.potential = title.substring(0, max_count);
                    data.potential_count = data.potential.length;
                }
                else {
                    data.potential = event.target.value;
                    data.potential_count = data.potential.length;
                }
                break;
            case 'schwachstelle':
                if (title.length >= max_count) {
                    data.schwachstelle = title.substring(0, max_count);
                    data.schwachstelle_count = data.schwachstelle.length;
                }
                else {
                    data.schwachstelle = event.target.value;
                    data.schwachstelle_count = data.schwachstelle.length;
                }
                break;
            case 'zukunft':
                if (title.length >= max_count) {
                    data.zukunft = title.substring(0, max_count);
                    data.zukunft_count = data.zukunft.length;
                }
                else {
                    data.zukunft = event.target.value;
                    data.zukunft_count = data.zukunft.length;
                }
                break;
        }

        this.setState({
            data: data
        });

    };

    schema = {
        teamname: Joi.string().required(),
        mitglieder: Joi.string().required(),
        titel: Joi.string().required(),
        titel_count: Joi.number().optional(),
        ziel: Joi.string().required(),
        ziel_count: Joi.number().optional(),
        motivation: Joi.string().required(),
        motivation_count: Joi.number().optional(),
        anwendung: Joi.string().required(),
        anwendung_count: Joi.number().optional(),
        datensatz: Joi.string().required(),
        datensatz_count: Joi.number().optional(),
        vorbereitung: Joi.string().required(),
        vorbereitung_count: Joi.number().optional(),
        dnn: Joi.string().required(),
        dnn_count: Joi.number().optional(),
        evaluation: Joi.string().required(),
        evaluation_count: Joi.number().optional(),
        produkt: Joi.string().required(),
        produkt_count: Joi.number().optional(),
        komponenten: Joi.string().required(),
        komponenten_count: Joi.number().optional(),
        anleitung: Joi.string().required(),
        anleitung_count: Joi.number().optional(),
        probleme: Joi.string().required(),
        probleme_count: Joi.number().optional(),
        ethnic: Joi.string().required(),
        ethnic_count: Joi.number().optional(),
        potential: Joi.string().required(),
        potential_count: Joi.number().optional(),
        schwachstelle: Joi.string().required(),
        schwachstelle_count: Joi.number().optional(),
        zukunft: Joi.string().required(),
        zukunft_count: Joi.number().optional(),
        video: Joi.array(),
        code: Joi.array(),
        files: Joi.array()

    }

    handleSave = (e) => {
        e.preventDefault();
        console.log("in save")
        const { canSubmitProp, canSubmitProject } = this.props;
        canSubmitProject();
        if (!canSubmitProp) return false;

        this.setState({
            loading: true,
            loadingError: ''
        })

        const { teamname, mitglieder, titel, ziel, motivation, anwendung, datensatz, vorbereitung, dnn, evaluation, produkt, komponenten, anleitung, probleme, ethnic, potential, schwachstelle, zukunft, video, code, files, finaleAbgabe } = this.state.data;

        // Project update
        if (this.state.hasSaved) {
            console.log("update project",  video, code, files)
            projectService.updateProject(teamname, mitglieder, titel, ziel, motivation, anwendung, datensatz, vorbereitung, dnn, evaluation, produkt, komponenten, anleitung, probleme, ethnic, potential, schwachstelle, zukunft, video, code, files, finaleAbgabe).then(({ data }) => {
                this.setState({
                    showSaved: true,
                    loading: false,
                    loadingError: '',
                    togglePopup: false
                })
            }).catch(err => {
                this.setState({
                    loading: false,
                    loadingError: 'Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.',
                    togglePopup: false
                })
            })
        }
        else {
            console.log("add project")
            projectService.addProject(teamname, mitglieder, titel, ziel, motivation, anwendung, datensatz, vorbereitung, dnn, evaluation, produkt, komponenten, anleitung, probleme, ethnic, potential, schwachstelle, zukunft, video, code, files, finaleAbgabe).then(({ data }) => {
                this.setState({
                    showSaved: true,
                    loading: false,
                    loadingError: '',
                    togglePopup: false
                })
            }).catch(err => {
                this.setState({
                    loading: false,
                    loadingError: 'Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.',
                    togglePopup: false
                })
            })

        }
    }

    submitForm = () => {
        const { canSubmitProp, canSubmitProject } = this.props;
        canSubmitProject();
        if (!canSubmitProp) return false;


        const { teamname, mitglieder, titel, ziel, motivation, anwendung, datensatz, vorbereitung, dnn, evaluation, produkt, komponenten, anleitung, probleme, ethnic, potential, schwachstelle, zukunft, video, code, files, finaleAbgabe } = this.state.data;

        this.setState({
            loading: true,
            loadingError: ''
        })

        if (this.state.hasSaved) {
            projectService.updateProject(teamname, mitglieder, titel, ziel, motivation, anwendung, datensatz, vorbereitung, dnn, evaluation, produkt, komponenten, anleitung, probleme, ethnic, potential, schwachstelle, zukunft, video, code, files, finaleAbgabe).then(({ data }) => {
                this.setState({
                    hasSubmitted:true,
                    showCompleted: true,
                    loading: false,
                    loadingError: '',
                    togglePopup: false
                })
            }).catch(err => {
                this.setState({
                    loading: false,
                    loadingError: 'Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.',
                    togglePopup: false
                })
            })
        }
        else {

            projectService.addProject(teamname, mitglieder, titel, ziel, motivation, anwendung, datensatz, vorbereitung, dnn, evaluation, produkt, komponenten, anleitung, probleme, ethnic, potential, schwachstelle, zukunft, video, code, files, finaleAbgabe).then(({ data }) => {
                this.setState({
                    hasSubmitted:true,
                    showCompleted: true,
                    loading: false,
                    loadingError: '',
                    togglePopup: false
                })
            }).catch(err => {
                this.setState({
                    loading: false,
                    loadingError: 'Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.',
                    togglePopup: false
                })
            })

        }

    }

    hideVideo = () => {
        this.setState({
            showVideo: false
        });
    }


    showConfirmationPopup = (event) => {
        event.preventDefault();
        this.setState({
            togglePopup: true
        });
    }

    onClosePupup = () => {
        this.setState({
            togglePopup: false
        });
    }
    onSubmitConfirmed = (event) => {
        this.handleSubmit(event);
    }

    onValidate = () => {

        let errors = this.validate()

        if (!this.state.data.code || this.state.data.code.length === 0) {

            errors = errors ? errors : {}
            errors['code'] = 'Codedatei darf nicht leer sein!'
        }

        if (!this.state.data.video || this.state.data.video.length === 0) {
            errors = errors ? errors : {}
            errors['video'] = 'Videodatei darf nicht leer sein!'
        }
        return errors
    }

    handleFinaleAbgabe = () => {
        const data = {...this.state.data, finaleAbgabe: !this.state.data.finaleAbgabe};
        this.setState({
            data: data
        })
    }

    resetFormData(updatedData) {
        updatedData.titel = '';
        updatedData.ziel = '';
        updatedData.motivation = '';
        updatedData.anwendung = '';
        updatedData.datensatz = '';
        updatedData.vorbereitung = '';
        updatedData.dnn = '';
        updatedData.evaluation = '';
        updatedData.produkt = '';
        updatedData.komponenten = '';
        updatedData.anleitung = '';
        updatedData.probleme = '';
        updatedData.potential = '';
        updatedData.schwachstelle = '';
        updatedData.zukunft = '';
        return updatedData;
    }

    render() {
        const { hasSubmitted: hasSubmitted, showCompleted, showSaved, togglePopup, showVideo, data, loading, loadingError, userData, showSubmission, notPartOfTeam, projectSubmitted, existingFileNames, imageError } = this.state;
        const { canSubmitProp } = this.props;

        return (
            <React.Fragment>
                <div className={`user-popup ${togglePopup ? 'active' : ''}`}>
                    <div className={`popup-box`}>
                        <span className={`text`} >Wenn du das Projekt einreichst, kannst du es anschliessend nicht mehr ändern.</span>
                        <div className={`popup-buttons`}>
                            <button disabled={this.state.loading} onClick={this.onSubmitConfirmed}>Bestätigen</button>
                            <button disabled={this.state.loading} onClick={this.onClosePupup.bind(this, '')}>Ablehnen</button>
                        </div>
                        {this.state.loading && <div className="in-progress">bitte warten.....</div>}
                    </div>
                </div>

                {
                    // !showCompleted && !showSaved &&
                    <section className="content question">
                        <div className="container-fluid">
                            <div className="container-limit my-limit">
                                <React.Fragment>
                                    {
                                        showSubmission &&
                                        <SubmitProjectForm canSubmitProp={canSubmitProp} 
                                                            loading={loading} 
                                                            loadingError={loadingError} 
                                                            data={data} 
                                                            handleChange={this.handleChange}
                                                            handleTextChange={this.handleTextChange}
                                                            handleCharChange={this.handleCharChange} 
                                                            handleSubmit={this.hanedleProject} 
                                                            validate={this.onValidate} 
                                                            showImageError={this.showImageError} 
                                                            user={userData}
                                                            showConfirmationPopup={this.showConfirmationPopup}
                                                            handleSave={this.handleSave}
                                                            projectSubmitted={hasSubmitted}
                                                            existingFileNames={existingFileNames}
                                                            showSaved={showSaved}
                                                            showCompleted={showCompleted}
                                                            imageError={imageError}
                                                            handleFinaleAbgabe={this.handleFinaleAbgabe}/>
                                    }
                                    {
                                        hasSubmitted && !showSubmission &&
                                        <div className="submitedIdea">
                                            <div className="submited-wrapper">
                                                <h1>Ihr habt euer Projekt bereits eingereicht.</h1>
                                            </div>
                                        </div>
                                    }
                                    {
                                        notPartOfTeam &&
                                        <div className="submitedIdea">
                                            <div className="submited-wrapper">
                                                <h1>Um ein Projektidee oder ein Projekt einzureichen, musst Du als Mitglied eines Teams registriert sein.</h1>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !this.props.canSubmitProp && <div className="submitedIdea">
                                            <div className="submited-wrapper">
                                                <h1>Die Frist für die Einreichung von Projekten ist abgelaufen. Sie können jetzt kein Projekt einreichen.</h1>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.props.canSubmitProp && !notPartOfTeam && !hasSubmitted && !showSubmission &&
                                         <Loading />
                                    }
                                </React.Fragment>
                            </div>
                        </div>
                    </section>
                }

            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        canSubmitProp: state.canSubmit
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        canSubmitProject: () => {
            dispatch(canSubmit())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
