import http from './httpService';

const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;

export async function register(username, password, confirmPassword, school, postNumber, captcha, fullName, birthday, email, parentEmail, mannlich, weiblich, divers, wohnOrt, phoneNumber, eventsCheck) {
    return await http.post(`${web_api_url}/user`, {
        username,
        password,
        confirmPassword,
        school,
        postNumber,
        captcha,
        fullName,
        birthday,
        email,
        parentEmail,
        mannlich, 
        weiblich, 
        divers,
        wohnOrt,
        phoneNumber,
        eventsCheck
    })
}

export async function changePassword(password, confirmPassword, token) {
    return await http.post(`${web_api_url}/change-password`, { password, confirmPassword, token })
}

export async function getUsers(){
    return await http.get(`${web_api_url}/users`)
}


export async function getUser(id) {
    return await http.get(`${web_api_url}/user/${id}`);
}

export async function editUser(id, fullName, postNumber, gender, school, wohnOrt, phoneNumber ) {
    return await http.put(`${web_api_url}/user/${id}`, { fullName,postNumber, wohnOrt, gender, school, phoneNumber })
}




export default {
    register,
    changePassword,
    getUser,
    getUsers,
    editUser
}