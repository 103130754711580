import React, { Component } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';
import * as alert from '../alert.json';

class Home extends Component {
    state = {
         paths : [{
            title:"Team",
            description:"Um beim BWKI mitzumachen musst du zuerst ein Team aus 1-4 TeilnehmerInnen gründen.",
            link:"/team-bilden",
            image:"team.svg",
            styleClass:"primary-color-blue-background"
        },
        {
            title:"Idea",
            description:"Du bist schon in einem Team? Dann könnt ihr jetzt eure Idee anmelden.",
            link:"/submit-ideas",
            image:"idea.svg",
            styleClass:"primary-color-lightblue-background"
        },
        {
            title:"Project",
            description:"Hier könnt ihr das Projekt zu eurer angemeldeten Idee einreichen.",
            link:"/submit-project",
            image:"project.svg",
            styleClass:"primary-color-lightgreen-background"
        }],
        showAlert: false,
        showPanel:false
    }

    componentDidMount() {
        
        if (alert.home.enabled) {
            this.setState({
                showAlert: true
            })
        }
    }


    toggleModal = () => {
        this.setState({
            showPanel: !this.state.showPanel,
        })
    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }
    render() {
        const user = authService.getCurrentUser();
        const { showPanel} = this.state;

        if (!user) this.props.history.push('/auth')
        return (
            <React.Fragment>
                <section className="content homepage">
                     <div>
                        
                        <div className="homepage header2 font-sub-header text-center" style={{marginBottom:"0.25rem"}}>Ihr wollt beim Bundeswettbewerb KI mitmachen und eure eigene Idee verwirklichen?</div>
                        <h1 className="homepage header1 font-main-header text-center message-default"  onClick={this.toggleModal}>So geht’s!</h1>
                    </div>
                    <div className="container-fluid home-wrapper">
                    <div className="path-tiles">
                        {
                            this.state.paths.map(path => (
                                <Link to={`${path.link}`} className="homepage-tile layout">
                                    <div className="layout-wrapper">
                                        <img className="tile-image" src={`/images/icons/paths/${path.image}`} />
                                        <div className="tile-text"> {path.description}</div>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                    </div>

                    { showPanel &&
                        <div className="overlay-panel-container">
                            <div className="overlay-panel competition-home">
                                <div>
                                    <div className="section">
                                        <p>
                                            Um ein Projekt beim Bundeswettbewerb KI einzureichen, müßt ihr:
                                            <br/>
                                            <span className="points">(1) ein Team gründen (auch wenn ihr nur eine Person seid),</span><br/>
                                            <span className="points">(2) eure Projektidee anmelden und</span><br/>
                                            <span className="points">(3) euer Projekt anschließend einreichen.</span><br/><br/>
                                            Für die <strong>Projekteinreichung</strong> benötigen wir einige Informationen zu eurer Arbeit. Die entsprechenden Textfelder könnt ihr bereits <strong>vor der Einreichung ausfüllen</strong>, abspeichern und bis zur Einreichung verändern. 
                                            <br/>
                                            <br/>
                                            Ihr sucht noch nach Teampartnern? Dann schaut in der Rubrik <strong>Team finden</strong> oder annonciert selbst.
                                        </p>

                                    </div>

                                </div>
                                <div className="close" onClick={this.toggleModal}></div>
                            </div>
                        </div>}
                </section>
            </React.Fragment>
        );
    }
}

export default Home;
