import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';
import auth from '../../services/authService';
import { Redirect } from 'react-router-dom';
import logo from '../../img/logo_bwki.png';
import ReCAPTCHA from 'react-google-recaptcha';
import userService from '../../services/userService';
import moment from 'moment';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { schools } from '../../schools.json'

class Register extends Form {
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
    }

    state = {
        data: {
            username: '',
            password: '',
            confirmPassword: '',
            school: '',
            postNumber: '',
            captcha: '',
            passwordValid: false,
            terms: false,
            fullName: '',
            birthday: '',
            email: '',
            parentEmail: '',
            showParent: false,
            mannlich: true,
            weiblich: false,
            divers: false,
            wohnOrt: '',
            phoneNumber: '',
            eventsCheck: false
        },
        options: schools.map(school => school),
        errors: {
        },
        registerError: '',
        loading: false,
        showOlderWarning: true,
        showYoungerWarning: true
    }

    getDate = (years) => {
        return moment().subtract(years, 'years')._d
    }

    schema = {
        username: Joi.string().regex(/^[a-zA-Z0-9]+$/).required().min(3).max(20).error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Der Benutzername sollte nicht leer sein!";
                        break;
                    case "string.regex":
                        err.message = "Der Benutzername sollte nur eine Kombination aus Buchstaben und Zahlen ohne Leerzeichen enthalten";
                        break;
                    case "string.min":
                        err.message = "Benutzername muss 3 bis 20 Zeichen lang sein";
                        break;
                    case "string.max":
                        err.message = "Benutzername muss 3 bis 20 Zeichen lang sein";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        password: Joi.string().required(),
        confirmPassword: Joi.string().allow('').optional(),
        school: Joi.string().required(),
        postNumber: Joi.string().allow('').optional().max(5),
        captcha: Joi.string().required(),
        passwordValid: Joi.boolean().required().invalid(false),
        terms: Joi.boolean().required().invalid(false),
        fullName: Joi.string().required(),
        email: Joi.string().email().required(),
        birthday: Joi.date().max(Date.now()),
        showParent: Joi.boolean().optional(),
        wohnOrt: Joi.string().required(),
        phoneNumber: Joi.string().regex(/^(((0043)\s?|(\+43)\s?)|0)((\d{3})\s?)\d{3,12}$/).required(),
        mannlich: Joi.boolean().required(),
        weiblich: Joi.when('mannlich', {
            is: false,
            then: Joi.boolean().required(),
            otherwise: Joi.boolean().optional()
        }),
        divers: Joi.when('mannlich', {
            is: false,
            then: Joi.when('weiblich', {
                is: false,
                then: Joi.boolean().required(),
                otherwise: Joi.boolean().optional()
            }),
        }),

        parentEmail: Joi.string().email().allow('').optional().when('birthday', {
            is: Joi.date().max(this.getDate(16)),
            then: Joi.string().email().allow('').optional(),
            otherwise: Joi.string().email().required()
        }),
        eventsCheck: Joi.boolean().optional()
    }

    handleConfirmPasswordChange = ({ currentTarget: input }) => {
        const schema = {
            password: Joi.string().required(),
            confirmPassword: Joi.string().valid(Joi.ref('password')).required(),
        }
        let fieldName = input.name;

        const { data, errors } = this.state;
        const { password, confirmPassword } = data;

        let obj;

        if (fieldName === 'password') {
            obj = {
                password: input.value,
                confirmPassword
            }
            data.password = input.value;
        }
        else {
            obj = {
                password,
                confirmPassword: input.value
            }
            data.confirmPassword = input.value;
        }

        const { error } = Joi.validate(obj, schema);
        delete errors['confirmPassword'];
        delete errors['password'];
        if (!error) {
            data.passwordValid = true;
        }
        else {
            data.passwordValid = false;
            error.details.forEach(err => {
                errors[err.path[0]] = 'error';
            })
        }
        this.setState({
            data,
            errors
        })
    }

    addOverflow = add => {
        if (add) document.getElementsByClassName('content-login')[0].classList.add('overflow');
        else document.getElementsByClassName('content-login')[0].classList.remove('overflow');
    }

    submitForm = e => {
        e.preventDefault();
        const { data, errors, options } = this.state;
        const { username, password, confirmPassword, school, captcha, postNumber, fullName, birthday, email, parentEmail, mannlich, weiblich, divers, wohnOrt, phoneNumber, eventsCheck } = data;

        const validateErrors = this.validate();
        this.setState({ errors: validateErrors || errors });
        if (validateErrors) return;
        if (!options.includes(data.school)) {
            data.school = '';
            this.setState({
                data,
                registerError: "Nur Schulen aus der Liste auswählen",
                loading: false
            })
            return;
        }

        this.setState({
            registerError: "",
            loading: true
        })

        userService.register(username, password, confirmPassword, school, postNumber, captcha, fullName, birthday, email, parentEmail, mannlich, weiblich, divers, wohnOrt, phoneNumber, eventsCheck).then(() => {
            window.location.reload();
        }).catch(err => {
            let error = "Es ist ein unerwarteter Fehler aufgetreten.";
            if (err.response && err.response.status === 400) {
                error = err.response.data.error;
            }

            data.captcha = '';
            this.setState({
                registerError: error,
                loading: false,
                data
            });

            this.recaptchaRef.current.reset();
        });
    }

    handleBirthdayChange = ({ currentTarget: input }) => {
        const { data } = this.state;

        setTimeout(() => {
            const errors = { ...this.state.errors };
            data.birthday = input.value;

            if (moment(input.value) < moment(this.getDate(13)) && moment(input.value) > moment(this.getDate(18))) {
                data.showParent = true;
            }
            else {
                data.showParent = false;
            }

            let showOlderWarning = false;
            if (moment(input.value) < moment(this.getDate(20))) {
                this.props.onShowOlderWarning(true);
                showOlderWarning = true;
            }
            else {
                this.props.onShowOlderWarning(false);
            }

            let showYoungerWarning = false;
            if (moment(input.value) > moment(this.getDate(13))) {
                this.props.onShowYoungerWarning(true);
                showYoungerWarning = true;
            }
            else {
                this.props.onShowYoungerWarning(false);
            }

            this.setState({
                data,
                errors,
                showOlderWarning,
                showYoungerWarning
            })
        }, 600);

    }


    handleIsMannlich = () => {
        const { data } = this.state;

        setTimeout(() => {
            const errors = { ...this.state.errors };
            data.mannlich = true;
            data.weiblich = false;
            data.divers = false;

            this.setState({
                data,
                errors
            })
        }, 600);
    }

    handleIsWeiblich = () => {
        const { data } = this.state;

        setTimeout(() => {
            const errors = { ...this.state.errors };
            data.mannlich = false;
            data.weiblich = true;
            data.divers = false;

            this.setState({
                data,
                errors
            })
        }, 600);
    }

    handleIsDivers = () => {
        const { data } = this.state;

        setTimeout(() => {
            const errors = { ...this.state.errors };
            data.mannlich = false;
            data.weiblich = false;
            data.divers = true;

            this.setState({
                data,
                errors
            })
        }, 600);
    }


    render() {
        let options = []
        schools.forEach(school => {
            options.push(<option>{school}</option>)
        })

        if (auth.getCurrentUser()) return <Redirect to='/' />
        const { activeTab, olderWarning, youngerWarning } = this.props;
        const { data, errors, loading, registerError } = this.state;
        const { username, password, confirmPassword, school, postNumber, terms, email, fullName, birthday, showParent, parentEmail, mannlich, weiblich, divers, wohnOrt, phoneNumber, eventsCheck } = data;

        return (
            <div className={`content-login-wrapper team-content ${activeTab === 'register' ? 'active' : ''}`}>
                <div className="login-logo ">
                    <img className="register" alt='logo' src={logo} />
                    <h1 className="register"> <span>Bundeswettbewerb</span> <br /> Künstliche Intelligenz</h1>
                </div>
                <a className="verlinkung" href="https://ki-kurs.asai.ac.at/">Hier geht's stattdessen zum KI-Kurs</a>
                <form className='reg-style-wrapper'>
                    <div className='row'>
                        <div className='form-group col-md-6'>
                            <input name='username' value={username} onChange={this.handleChange} type="text" placeholder={"Benutzername"} className={`form-control ${errors.username ? 'invalid' : ''}`} />
                            {errors.username &&
                                <small className="form-text primary-color-blue-foreground mt-1">{errors.username}</small>
                            }
                        </div>
                        <div className='form-group col-md-6'>
                            <input name='email' value={email} onChange={this.handleChange} type="email" placeholder="Email" className={`form-control ${errors.email ? 'invalid' : ''}`} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6'>
                            <input name='password' value={password} onChange={this.handleConfirmPasswordChange} type="password" placeholder="Passwort" className={`form-control ${errors.password ? 'invalid' : ''}`} />
                        </div>
                        <div className='form-group col-md-6'>
                            <input name='confirmPassword' value={confirmPassword} onChange={this.handleConfirmPasswordChange} type="password" placeholder="Passwort wiederholen" className={`form-control ${errors.confirmPassword ? 'invalid' : ''}`} />
                        </div >
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6'>
                            <DatePicker style={{ display: "inline" }} className='form-control'
                                //locale='de' 
                                dateFormat='yyyy-MM-dd'
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholderText='Datum JJJJ-MM-TT'
                                selected={birthday}
                                onChange={(e) => this.handleBirthdayChange({ currentTarget: { value: e, name: 'birthday' } })}
                                popperModifiers={{
                                    preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: "viewport"
                                    }
                                }}
                            />
                        </div>
                        <div className='form-group col-md-6'>
                            <input name='parentEmail' value={parentEmail} onChange={this.handleChange} type="text" disabled={!data.showParent} placeholder="Email des Erziehungsberechtigten" className={`form-control ${errors.parentEmail ? 'invalid' : ''}`} />
                        </div>
                    </div>
                    {/* <div className='row'>
                        <div className='col-md-3'>
                        <h6>Bist du unter 18?</h6>
                        </div>
                        <div className='form-check-inline'>
                            <label htmlFor="ageBelow18" className="form-check-label">
                            <input type="radio" id="ageBelow18" name="ageBelow18" checked={ageBelow18} value={ageBelow18} onChange={this.handleAgeBelow18Change} className='form-check-input' />
                            Ja
                        </label>
                        </div>
                        <div className='form-check-inline'>
                            <label htmlFor="ageAbove18" className="form-check-label">
                            <input type="radio" id="ageAbove18" name="ageAbove18" checked={ageAbove18} value={ageAbove18} onChange={this.handleAgeAbove18Change} className='form-check-input' />
                            Nein
                            </label>
                        </div>
                    </div> */}
                    <div className='row'>
                        <div className='col-md-12'>
                            {showParent && <p className='olderWarning'>Dann benötigen wir die Zustimmung deiner Eltern für deine Teilnahme am Kurs.</p>}
                            {olderWarning && <p className='olderWarning'>Personen über 20 Jahren können sich zwar registrieren und die Web-Applikation für Lernzwecke nutzen, werden aber in dem Bundeswettbewerb selbst nicht berücksichtigt.</p>}
                            {youngerWarning && <p className='olderWarning'>Du musst mindestens 13 Jahre alt sein, um dich registireren zu können.</p>}
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='form-group col-md-6'>
                            <input name='fullName' value={fullName} onChange={this.handleChange} type="text" placeholder="Vor- & Nachname" className={`form-control ${errors.fullName ? 'invalid' : ''}`} />
                        </div>
                        <div className='col-md-6'>
                            <h6>Geschlecht</h6>
                            <div className="form-check-inline">
                                <label htmlFor="mannlich" className={`form-check-label`}>
                                    <input type="radio" id="mannlich" name="mannlich" checked={mannlich} value={mannlich} onChange={this.handleIsMannlich} className='form-check-input' />
                                    männlich
                                </label>
                            </div>
                            <div className="form-check-inline">
                                <label htmlFor="weiblich" className={`form-check-label`}>
                                    <input type="radio" id="weiblich" name="weiblich" checked={weiblich} value={weiblich} onChange={this.handleIsWeiblich} className='form-check-input' />
                                    weiblich
                                </label>
                            </div>
                            <div className="form-check-inline">
                                <label htmlFor="divers" className={`form-check-label`}>
                                    <input type="radio" id="divers" name="divers" checked={divers} value={divers} onChange={this.handleIsDivers} className='form-check-input' />
                                    divers
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='form-group col-md-6'>
                            <input name='postNumber' value={postNumber} onChange={this.handleChange} type="text" placeholder="PLZ" className={`form-control ${errors.postNumber ? 'invalid' : ''}`} />
                        </div>
                        <div className='form-group col-md-6'>
                            <input name='wohnOrt' value={wohnOrt} onChange={this.handleChange} type="text" placeholder="Wohnort" className={`form-control ${errors.wohnOrt ? 'invalid' : ''}`} />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='form-group col-md-6'>
                            <input name='phoneNumber' value={phoneNumber} onChange={this.handleChange} type="tel" placeholder="Telefonnummer" className={`form-control ${errors.phoneNumber ? 'invalid' : ''}`} />
                        </div>
                        <div className='form-group col-md-6'>
                            <input list='schools' name='school' value={school} onChange={this.handleChange} type="text" placeholder={"Schule"} className={`form-control ${errors.school ? 'invalid' : ''}`} />
                            <datalist id='schools'>
                                {options}
                            </datalist>
                            <small className="form-text primary-color-blue-foreground mt-1">Deine Schule ist nicht in der Liste? Wähle sonstige und tippe den Namen der Schule nach der Anmeldung im Benutzerprofil ein. </small>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-12'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" onChange={this.handleChange} value={eventsCheck} name="eventsCheck" />
                                <label className="form-check-label" for="check-events">
                                    Ja, ich möchte zu weiteren Aktivitäten des BWKI informiert werden
                        </label>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-6 '>
                            <ReCAPTCHA ref={this.recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={e => this.handleCaptcha(e, 'captcha')} onExpired={e => this.handleCaptcha('', 'captcha')} />
                        </div>
                        <div className='col-sm-2'>
                            <div className='mt-3 checkbox-wrapper'>
                                <div className="chechbox-terms">
                                    <label className="switch">
                                        <input name='terms' value={terms} onChange={this.handleChange} type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class='col-md-4'>
                            <small className='text-justify'>
                                Mit deiner Anmeldung erkennst du unsere <a href="https://bwki.asai.ac.at/dsgvo" target='_blank' rel="noopener noreferrer">Datenschutzbedingungen</a> und die <a href="https://bwki.asai.ac.at/teilnahmebedingungen" target='_blank' rel="noopener noreferrer">Teilnahmebedingungen</a> an</small>
                        </div>
                    </div>
                </form>
                {
                    registerError &&
                    <div className="error text-center">
                        <span className="text-danger">{registerError}</span>
                    </div>
                }
                <div className="button-login register">
                    <button type='button' disabled={loading ? true : this.validate()} onClick={this.submitForm}>Jetzt mitmachen</button>
                </div>
            </div>
        );
    }
}

export default Register;
