import http from './httpService';
import jwt_decode from 'jwt-decode'

const {
    REACT_APP_WEB_API_URL: web_api_url,
    REACT_APP_TOKEN_NAME: tokenName
} = process.env;

http.setJwt(getJwt())

export async function login(username, password) {
    return await http.post(`${web_api_url}/auth`, {
        username: username,
        password: password
    }).then(({ data }) => {
        localStorage.setItem(tokenName, data.access_token);
        http.setJwt(getJwt())
    });
}

export function logout() {
    localStorage.removeItem(tokenName);
}

export function getJwt() {
    return localStorage.getItem(tokenName)
}

export function getUserName() {
    return getCurrentUser().username;
}

export function getUserId() {
    return getCurrentUser().id;
}

export function getUserIdColor(id) {
    let userid = id;
    if (!userid) userid = getUserId();
    let color = "#" + userid.substr(userid.length - 6, userid.length);
    return color;
}

export function shortTitle(title)
{
    if(title.length > 80)
    {
        let newString = title;
        let shortTitle = newString.substr(0, 80) + '...';
        title=shortTitle;
        return shortTitle;
    }
    return title;
}

export function getCurrentUser() {
    try {
        const token = getJwt();
        const decoded = jwt_decode(token);
        if (new Date().getTime() / 1000 > decoded.exp) {
            //expired
            localStorage.removeItem(tokenName);
            return null;
        }
        return decoded.user_claims;
    } catch (ex) {
        return null;
    }
}

export async function forgotPassword(email) {
    return http.post(`${web_api_url}/forgot-password`, {
        email
    });
}

export async function verifyChangePasswordToken(token) {
    return await http.get(`${web_api_url}/verify-token/${token}`);
}

export async function confirmUser(token) {
    return await http.post(`${web_api_url}/confirm-user`, { token });
}

export async function confirmTeam(token, team) {
    return await http.post(`${web_api_url}/team-confirm`, { token, team });
}


export default {
    login,
    logout,
    getJwt,
    getCurrentUser,
    forgotPassword,
    verifyChangePasswordToken,
    confirmUser,
    confirmTeam
}
