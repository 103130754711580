import React, { Component } from 'react';
import logo from '../img/logo_bwki.png';
import { Link } from 'react-router-dom';

class NavBar extends Component {
  render() {
    const { role } = this.props;
    return (
      <React.Fragment>
        <section className="header">
          <div className="container-fluid">
            <div className={`header-wrapper ${role ? 'entry' : ''}`}>
              {role &&
                <div className="nav-logo">
                  <Link to='/'><img alt='logo' src={logo} /></Link>
                  <Link to='/'><h1> <span>Bundeswettbewerb</span> <br /> Künstliche Intelligenz </h1></Link>
                </div>
              }
              {!role &&
                <div className="header-nav-wrapper">
                  <div className="nav-logo">
                    <Link to='/'><img alt='logo' src={logo} /></Link>
                    <Link to='/'><h1> <span>Bundeswettbewerb</span> <br /> Künstliche Intelligenz </h1></Link>
                  </div>
                  <div className="header-wrapper-center">
                    <Link to='/'>Einreichung<div className="div-helper"></div></Link>
                    <Link to='/team-findung'>Team-Findung<div className="div-helper"></div></Link>
                    <Link to='/user-profile'>Benutzerprofil & Team gründen<div className="div-helper"></div></Link>
                  </div>
                  <div className="header-wrapper-right">
                    <Link to='/logout'>Abmelden<div className="div-helper"></div></Link>
                  </div>
                </div>
              }
            </div>
          </div>
        </section>
        <div className="nav-helper"></div>
      </React.Fragment>
    );
  }
}

export default NavBar;
